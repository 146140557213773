import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Alert, Box, Checkbox, CircularProgress, Divider, IconButton, Typography } from '@mui/material';
import { ArrowCircleRight, Close, Info, ShoppingCart, Warning } from '@mui/icons-material';

import { AuthContext } from '@/contexts/auth-context';
import useHttp from '@/hooks/useHttp';
import toast from 'react-hot-toast';
import { useConfig } from '@/contexts/config-context';
import InputMask from '../inputs/input-mask';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalLogin({
    open,
    onClose
}) {
    const { post } = useHttp();
    const [data, setData] = React.useState({ value: "" });
    const context = React.useContext(AuthContext);
    const [mode, setMode] = React.useState("login");


    const getClient = async () => {
        if (String(data.value).replace(/[^\d]/g, "").length < 10) {
            toast.error("Informe um telefone válido");
            return;

        }
        let result = await post(`/api/clients/find?value=${data.value}`);
        if (result?.user == null && result !== null) {
            toast.error("Usuário não encontrado");
        } else {
            if (result?.user) {
                localStorage.setItem("token", result?.user?.token)
                context.setUser(result?.user)

                setMode("logged");
                onClose();
            } else {

            }

            // context.refresh();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleConfirmLogin = async () => {
        // if (!context.user) {
        await getClient()
        // }
    }

    React.useEffect(() => {
        if (context.user) {
            setMode("logged");
        }
    }, [context.user])

    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }} className='flex items-center justify-between'>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                            <ShoppingCart /> <b>Login</b>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <Box sx={{ mt: 1 }}>
                    <LoginContent
                        setMode={setMode}
                        handleConfirm={handleConfirmLogin}
                        onClose={onClose}
                        data={data}
                        setData={setData}
                    />
                </Box>

            </Dialog>
        </React.Fragment>
    );
}

const fieldsToShow = {
    "phone": {
        label: "Telefone",
        mask: "(99) 9 9999-9999"
    },
    "cpf": {
        label: "CPF",
        mask: "999.999.999-99"
    },
}

const LoginContent = ({
    handleConfirm,
    value,
    raffle,
    data,
    setData
}) => {
    const config = useConfig();
    const [loading, setLoading] = React.useState(false);

    const innerConfirm = async () => {
        setLoading(true);

        await handleConfirm();
        setLoading(false);
    }

    return (
        <>
            <DialogContent style={{
                paddingTop: 18,
            }}>
                <InputMask
                    className="my-4"
                    label={`Informe seu ${fieldsToShow[config.login_mode]?.label}`}
                    mask={fieldsToShow[config.login_mode]?.mask}
                    value={data.value}
                    onChange={e => setData({ ...data, value: e.target.value })}
                />
                <Alert sx={{ my: 2 }} severity="warning" icon={<Warning />}>
                    Informe seu {fieldsToShow[config.login_mode]?.label} para continuar.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    sx={{ height: 45 }}
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={innerConfirm}
                >
                    {loading && <CircularProgress size={20} sx={{ marginRight: 2 }} />}
                    <b>Login</b> <ArrowCircleRight />
                </Button>
            </DialogActions>
        </>
    );
};
