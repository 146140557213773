import { TextField } from '@mui/material'
import React from 'react'
import InputMaskLib from 'react-input-mask';

export default function InputMask({
    label,
    style,
    placeholder,
    value,
    mask,
    onChange,
    className,
    type = 'text'
}) {
    return (
        <div className={className}>
            <InputMaskLib mask={mask} value={value} onChange={onChange}>
                {(inputProps) => <TextField style={style} type={type} fullWidth label={label} placeholder={placeholder} variant="outlined" {...inputProps} />}
            </InputMaskLib>
        </div>
    )
}
